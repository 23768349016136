.wrapper {
  padding: var(--mantine-spacing-xl) 0;
  background-color: var(--mantine-color-body);
}

.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: rem(36px);
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: rem(20);
  text-align: center;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.subtitle {
  color: var(--mantine-color-dimmed);
  text-align: center;
  max-width: rem(600);
  margin: 0 auto rem(60);
}

.grid {
  margin-top: rem(40);
}

.clientCard {
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  transition: transform 200ms ease, box-shadow 200ms ease;
  cursor: pointer;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: var(--mantine-shadow-md);
  }
}

.cardTitle {
  font-family: Greycliff CF, var(--mantine-font-family);
  line-height: 1.2;
}

.modalContent {
  padding: var(--mantine-spacing-md);
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    padding: var(--mantine-spacing-xl) calc(var(--mantine-spacing-xl) * 2);
    max-width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: var(--mantine-spacing-xl);
  }
}

.modalSection {
  margin-bottom: var(--mantine-spacing-xl);

  @media (min-width: var(--mantine-breakpoint-md)) {
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  }
}

.modalSectionTitle {
  font-size: rem(20);
  font-weight: 700;
  margin-bottom: var(--mantine-spacing-md);
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    font-size: rem(24);
    margin-bottom: var(--mantine-spacing-lg);
  }
}

@media (max-width: 768px) {
  .title {
    font-size: rem(28px);
    margin-bottom: rem(15);
  }

  .subtitle {
    margin-bottom: rem(40);
  }

  .grid {
    margin-top: rem(20);
  }
}
