.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

@media (max-width: 48em) {
  .main {
    margin-top: 60px;
  }
}
