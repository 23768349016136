.title {
  font-size: rem(34px);
  font-weight: 900;
  text-align: center;

  @media (max-width: $mantine-breakpoint-sm) {
    font-size: rem(24px);
  }
}

.description {
  max-width: rem(600px);
  margin: 0 auto;
  margin-top: var(--mantine-spacing-xl);
  margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  text-align: center;
  font-size: var(--mantine-font-size-lg);
}

.card {
  border: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  transition: transform 150ms ease, box-shadow 150ms ease;

  &:hover {
    transform: scale(1.01);
    box-shadow: var(--mantine-shadow-md);
  }
}

.techIcon {
  margin-right: rem(5px);
  color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-2));
}

.statCard {
  padding: var(--mantine-spacing-xl);
  background: linear-gradient(45deg, var(--mantine-color-blue-6), var(--mantine-color-cyan-6));
  border-radius: var(--mantine-radius-md);
  color: var(--mantine-color-white);
}

.statValue {
  font-size: rem(24px);
  font-weight: 700;
  line-height: 1;
}

.statTitle {
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;
  opacity: 0.7;
  text-transform: uppercase;
  margin-top: rem(7px);
}
