.wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow-x: hidden;
}

.header {
  text-align: center;
  margin-bottom: var(--mantine-spacing-xl);
}

.sectionTitle {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: 36px;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: var(--mantine-spacing-xl);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  text-align: center;
  word-break: break-word;
}

.description {
  max-width: rem(600);
  margin: var(--mantine-spacing-md) auto;
}

.viewAllButton {
  margin: var(--mantine-spacing-md) auto 0;
}

.card {
  transition: transform 150ms ease, box-shadow 150ms ease;
  cursor: pointer;
  height: auto;
  min-height: rem(180);
  display: flex;
  flex-direction: column;
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  border: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: var(--mantine-shadow-sm);
}

.cardTitle {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: rem(18);
  font-weight: 700;
  line-height: 1.2;
  margin-right: var(--mantine-spacing-xs);
}

.cardContent {
  padding: var(--mantine-spacing-lg);
  width: 100%;
  max-width: 100%;
}

.category {
  color: var(--mantine-color-white);
  opacity: 0.7;
  font-weight: 700;
  text-transform: uppercase;
}

.image {
  height: 180px;
  background-size: cover;
  background-position: center;
  border-radius: var(--mantine-radius-sm);
}

[data-mantine-color-scheme="dark"] .sectionTitle {
  color: var(--mantine-color-white);
}

@media (max-width: 768px) {
  .header {
    padding: 0 var(--mantine-spacing-md);
  }

  .description {
    font-size: var(--mantine-font-size-md);
    margin: var(--mantine-spacing-sm) auto;
  }

  .cardTitle {
    font-size: rem(16);
  }

  .card {
    min-height: rem(150);
  }

  .cardContent {
    padding: var(--mantine-spacing-md);
  }
}

@media (max-width: var(--mantine-breakpoint-sm)) {
  .wrapper {
    padding: var(--mantine-spacing-xl);
  }

  .sectionTitle {
    font-size: 28px;
  }
}
