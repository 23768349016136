.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow-x: hidden;

  @media (max-width: var(--mantine-breakpoint-l)) {
    min-height: auto;
    align-items: flex-start;
    padding-top: 8vh; /* Reduced to keep content above fold */
  }
}

.inner {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--mantine-spacing-xl) * 1.5);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    gap: var(--mantine-spacing-lg);
    padding: 0;
  }
}

.content {
  position: relative;
  text-align: center;
  max-width: rem(900px);
  margin: 0 auto;
  padding: 0 var(--mantine-spacing-md);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    padding: 0 var(--mantine-spacing-sm);
    margin-top: var(--mantine-spacing-lg); /* Adjusted for mobile */
  }
}

.title {
  text-align: center;
  font-family: 'Greycliff CF', var(--mantine-font-family);
  font-size: rem(50px);
  line-height: 1.1;
  font-weight: 900;
  margin-bottom: var(--mantine-spacing-lg);
  color: var(--mantine-color-black);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(38px);
    margin-bottom: var(--mantine-spacing-md);
    line-height: 1.15;
  }

  @media (max-width: var(--mantine-breakpoint-xs)) {
    font-size: rem(32px);
    margin-bottom: var(--mantine-spacing-sm);
  }
}

.description {
  margin-top: var(--mantine-spacing-xs);
  color: var(--mantine-color-dimmed);
  font-size: rem(18px);
  line-height: 1.5;
  
  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(16px);
    margin-bottom: var(--mantine-spacing-sm);
  }
}

.highlight {
  background: linear-gradient(90deg, var(--mantine-color-blue-5) 0%, var(--mantine-color-cyan-5) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.features {
  width: 100%;
  padding: 0;
  margin-top: var(--mantine-spacing-lg);
  
  @media (max-width: var(--mantine-breakpoint-sm)) {
    margin-top: var(--mantine-spacing-md);
  }
}

.feature {
  position: relative;
  padding: var(--mantine-spacing-md);
  background: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-sm);
  margin-bottom: var(--mantine-spacing-md);
}

.featureIcon {
  background: linear-gradient(45deg, var(--mantine-color-blue-6) 0%, var(--mantine-color-cyan-6) 100%);
  margin-bottom: var(--mantine-spacing-md);
}

/* "Get Started" button wrapper */
.buttonWrapper {
  margin-top: var(--mantine-spacing-lg);
  display: flex;
  justify-content: center;
}

/* "Get Started" button - Visible on larger screens, hidden on mobile */
.control {
  background: linear-gradient(
    45deg,
    var(--mantine-color-blue-filled) 0%,
    var(--mantine-color-cyan-filled) 100%
  );
  border: none;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);
  font-size: rem(18px);
  font-weight: 700;
  border-radius: var(--mantine-radius-md);
  transition: transform 150ms ease;
  
  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }

  @media (max-width: 768px) {
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
    font-size: rem(16px);
    margin-top: var(--mantine-spacing-md);
    display: block !important;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.stat {
  transition: transform 200ms ease, box-shadow 200ms ease;
  text-align: center;
  padding: var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
  background-color: var(--mantine-color-white);

  &:hover {
    transform: scale(1.03);
    box-shadow: var(--mantine-shadow-md);
  }
}

.statValue {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: Greycliff CF, var(--mantine-font-family);
  line-height: 1;
  margin-bottom: 0.5rem;
  color: var(--mantine-primary-color-filled);
}

.statLabel {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--mantine-color-dimmed);
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  left: 30%;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
  opacity: 0.8;

  @media (max-width: var(--mantine-breakpoint-sm)) {
    display: none;
  }
}

.featureBody {
  padding-left: var(--mantine-spacing-lg);
  margin-top: 0;
}

.featureTitle {
  font-weight: 700;
  font-size: var(--mantine-font-size-lg);
  line-height: 1;
  margin-bottom: var(--mantine-spacing-xs);
}

.featureDescription {
  font-size: var(--mantine-font-size-sm);
  letter-spacing: 0.5px;
  color: var(--mantine-color-dimmed);
}

/* Mobile-Specific Adjustments */
@media (max-width: 768px) {
  .wrapper {
    padding-top: 10vh; /* Reduced to keep content above fold */
    padding-bottom: 5vh;
  }

  .content {
    padding: var(--mantine-spacing-sm);
    margin-top: var(--mantine-spacing-xl); /* Further pushes title down */
  }

  .title {
    font-size: rem(32px);
    line-height: 1.2;
  }

  .description {
    font-size: rem(16px);
  }

  .features {
    margin-top: var(--mantine-spacing-md);
  }

  .buttonWrapper {
    display: none !important; /* Hide button wrapper on mobile */
  }
}