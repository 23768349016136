.wrapper {
  padding-top: calc(var(--mantine-spacing-xl) * 2);
  padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  min-height: 100vh;
}

.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  color: var(--mantine-color-black);
  line-height: 1;
  margin-bottom: var(--mantine-spacing-md);
  font-size: rem(34px);
  font-weight: 900;
}

.description {
  color: var(--mantine-color-dimmed);
  max-width: rem(300px);
}

.form {
  background-color: var(--mantine-color-white);
  padding: var(--mantine-spacing-xl);
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-lg);
}

.social {
  color: var(--mantine-color-black);
  transition: color 200ms ease;
}

.social:hover {
  color: var(--mantine-primary-color-filled);
}

.input {
  background-color: var(--mantine-color-white);
  border-color: var(--mantine-color-gray-4);
  color: var(--mantine-color-black);
}

.input::placeholder {
  color: var(--mantine-color-gray-5);
}

.inputLabel {
  color: var(--mantine-color-black);
}

.control {
  background-color: var(--mantine-primary-color-filled);
  border: 0;
}

.control:hover {
  background-color: var(--mantine-primary-color-filled-hover);
}

.itemTitle {
  color: var(--mantine-color-black);
}

.itemDescription {
  color: var(--mantine-color-dimmed);
}

.itemIcon {
  color: var(--mantine-color-white);
  background-color: var(--mantine-primary-color-filled);
}

@media (max-width: var(--mantine-breakpoint-md)) {
  .wrapper {
    padding-top: calc(var(--mantine-spacing-xl) * 1.5);
    padding-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  }

  .title {
    font-size: rem(28px);
  }

  .description {
    max-width: 100%;
  }

  .form {
    padding: var(--mantine-spacing-md);
  }
}
