.modalTitle {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: Greycliff CF, var(--mantine-font-family);
  line-height: 1.2;
  font-size: rem(24);
  font-weight: 700;
  margin-bottom: var(--mantine-spacing-xs);
}

.modalHeader {
  margin-bottom: 0;
  padding: var(--mantine-spacing-md);
  border-bottom: 1px solid var(--mantine-color-gray-2);
  margin-bottom: var(--mantine-spacing-md);
}

.modalBody {
  padding: var(--mantine-spacing-xl);
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    padding: var(--mantine-spacing-xl) calc(var(--mantine-spacing-xl) * 2);
    max-width: 80%;
    margin: 0 auto;
  }
}

.content {
  padding: var(--mantine-spacing-md);
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    padding: var(--mantine-spacing-xl);
    max-width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: var(--mantine-spacing-xl);
  }
}

.section {
  margin-bottom: var(--mantine-spacing-xl);
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    margin-bottom: calc(var(--mantine-spacing-xl) * 1.5);
  }
}

.sectionTitle {
  font-size: rem(20);
  font-weight: 700;
  margin-bottom: var(--mantine-spacing-md);
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    font-size: rem(24);
    margin-bottom: var(--mantine-spacing-lg);
  }
}

.testimonial {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  padding: var(--mantine-spacing-lg);
  border-radius: var(--mantine-radius-md);
  margin: var(--mantine-spacing-md) 0;
  
  @media (min-width: var(--mantine-breakpoint-md)) {
    padding: var(--mantine-spacing-xl);
    margin: var(--mantine-spacing-xl) 0;
  }
}

@media (max-width: var(--mantine-breakpoint-sm)) {
  .modalHeader {
    padding: var(--mantine-spacing-xs);
  }

  .modalTitle {
    font-size: rem(20);
  }

  .testimonial {
    padding: var(--mantine-spacing-sm);
  }
}
