.wrapper {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  background: var(--mantine-color-body);
  will-change: opacity;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: var(--mantine-breakpoint-sm)) {
    padding: 0;
  }
}

.inner {
  position: relative;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.section {
  padding: 0;
}

/* Ensure all sections stay within bounds */
section {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Handle long text content */
p, h1, h2, h3, h4, h5, h6 {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* Ensure all images are responsive */
img {
  max-width: 100%;
  height: auto;
  display: block;
}
