.wrapper {
  padding-top: 80px;
  padding-bottom: 80px;
}

.aboutSection {
  margin-bottom: 2rem;
}

.title {
  font-family: 'Greycliff CF', var(--mantine-font-family);
  font-size: rem(36px);
  font-weight: 900;
  line-height: 1.1;
  text-align: center;
  margin-bottom: var(--mantine-spacing-xl);

  @media (max-width: 48em) {
    font-size: rem(28px);
    padding: 0 var(--mantine-spacing-md);
  }
}

.aboutContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.imageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.textContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
}

.profileImage {
  width: 100%;
  max-width: rem(400);
  margin: 0 auto;
  display: block;
  box-shadow: var(--mantine-shadow-lg);
  
  @media (max-width: var(--mantine-breakpoint-md)) {
    max-width: rem(300);
    margin-bottom: var(--mantine-spacing-lg);
  }
}

.description {
  font-size: var(--mantine-font-size-lg);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  line-height: 1.6;
  max-width: rem(900);
  margin: 0 auto;
}

.mission {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--mantine-color-gray-7);
  white-space: pre-wrap;
  width: 100%;
}

.italicText {
  font-style: italic;
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  line-height: 1.6;
  max-width: rem(800);
  margin: 0 auto;
  font-weight: 500;
}

.highlight {
  background: linear-gradient(90deg, var(--mantine-color-blue-5) 0%, var(--mantine-color-cyan-5) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.skillsSection {
  margin-top: 3rem;
}

.featureIcon {
  background-color: var(--mantine-color-blue-6);
  margin-bottom: 1rem;
}

.skillItem {
  padding: 1.5rem;
  border-radius: var(--mantine-radius-md);
  background-color: var(--mantine-color-gray-0);
  transition: all 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.skillItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.progressBar {
  background-image: linear-gradient(45deg, 
    var(--mantine-primary-color-filled), 
    var(--mantine-color-blue-4)
  );
}

.control {
  background: linear-gradient(
    45deg,
    var(--mantine-color-blue-filled) 0%,
    var(--mantine-color-cyan-filled) 100%
  );
  border: none;
  position: relative;
  transition: transform 150ms ease;
  
  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
}

.getStarted {
  background-color: var(--mantine-color-blue-6);
  border: 0;
  color: var(--mantine-color-white);
  transition: all 0.3s ease;
  padding: var(--mantine-spacing-md) var(--mantine-spacing-lg);
  font-size: var(--mantine-font-size-md);
  border-radius: var(--mantine-radius-md);
  cursor: pointer;
}

.getStarted:hover {
  background-color: var(--mantine-color-blue-7);
  transform: translateY(-2px);
  box-shadow: var(--mantine-shadow-md);
}

.statsSection {
  background: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
  padding: var(--mantine-spacing-xl) var(--mantine-spacing-md);
  border-radius: var(--mantine-radius-md);
  margin: var(--mantine-spacing-xl) 0;
}

.statsGrid {
  max-width: rem(900px);
  margin: 0 auto;
  gap: var(--mantine-spacing-xl);
  text-align: center;
}

.statValue {
  font-family: 'Greycliff CF', var(--mantine-font-family);
  font-size: rem(32px);
  font-weight: 900;
  margin-bottom: var(--mantine-spacing-xs);
  background: linear-gradient(45deg, var(--mantine-color-blue-6) 0%, var(--mantine-color-cyan-6) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(28px);
  }
}

.statLabel {
  font-family: 'Greycliff CF', var(--mantine-font-family);
  text-transform: uppercase;
  font-weight: 700;
  font-size: rem(13px);
  color: var(--mantine-color-dimmed);
}

/* Laptop and larger screens */
@media (min-width: 992px) {
  .title {
    text-align: left;
    margin-bottom: 3rem;
  }

  .aboutContent {
    flex-direction: row;
    align-items: flex-start;
    gap: 4rem;
  }

  .imageWrapper {
    flex: 0 0 auto;
    width: auto;
  }

  .textContent {
    flex: 1;
    text-align: left;
  }

  .profileImage {
    max-width: 320px;
  }

  .skillsSection {
    margin-top: 5rem;
  }

  .skillItem {
    padding: 2rem 1.5rem;
  }
}

@media (min-width: 768px) {
  .aboutContent {
    align-items: flex-start;
    text-align: left;
  }
}

@media (max-width: var(--mantine-breakpoint-sm)) {
  .wrapper {
    padding: var(--mantine-spacing-xl);
  }
}
