.header {
  background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  padding-top: calc(var(--mantine-spacing-xl));
  padding-bottom: calc(var(--mantine-spacing-xl));
  margin-bottom: var(--mantine-spacing-md);
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
}

/* Add padding to the bottom of the page */
.wrapper {
  padding-bottom: calc(var(--mantine-spacing-xl) * 3);
}

.title {
  font-size: rem(34px);
  font-weight: 900;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(24px);
  }
}

.description {
  max-width: rem(600px);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
}

.card {
  transition: transform 150ms ease, box-shadow 150ms ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
  max-width: 360px;
  margin: 0 auto;

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--mantine-shadow-md);
  }
}

.image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 120px;
  position: relative;
  padding: var(--mantine-spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  max-height: 100px;
  width: auto;
  object-fit: contain;
  max-width: 100%;
}
