.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media (min-width: 1200px) {
    min-height: auto;
    height: calc(100vh - 80px);
    overflow: hidden;
  }

  @media (max-width: 1200px) {
    min-height: auto;
    align-items: flex-start;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}

.mainContainer,
.inner,
.content {
  @media (min-width: 1200px) {
    height: 100%;
    overflow: hidden;
  }
}

:global(body) {
  @media (min-width: 1200px) {
    overflow-y: hidden;
  }
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: var(--mantine-breakpoint-sm)) {
    align-items: flex-start;
  }
}

.inner {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--mantine-spacing-xl) * 1.5);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    gap: var(--mantine-spacing-lg);
    padding: 0;
  }
}

.content {
  position: relative;
  text-align: center;
  max-width: rem(900px);
  margin: 0 auto;
  padding: 0 var(--mantine-spacing-md);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    padding: 0 var(--mantine-spacing-sm);
    margin-top: var(--mantine-spacing-lg);
  }
}

.inner {
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--mantine-spacing-xs);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    all: unset;
    display: block;
    width: 100%;
  }
}

.content {
  position: relative;
  text-align: left;
  max-width: rem(900px);
  margin: 0;
  padding: 0;

  @media (max-width: var(--mantine-breakpoint-sm)) {
    all: unset;
    display: block;
    width: 100%;
    margin-bottom: 2rem;
  }
}

.title {
  text-align: center;
  font-family: 'Greycliff CF', var(--mantine-font-family);
  font-size: rem(40px);
  line-height: 1.2;
  font-weight: 900;
  margin-bottom: var(--mantine-spacing-md);
  color: var(--mantine-color-black);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(28px);
    line-height: 1.3;
    margin-bottom: var(--mantine-spacing-sm);
    br {
      display: none;
    }
  }

  @media (max-width: var(--mantine-breakpoint-xs)) {
    font-size: rem(24px);
  }
}

.description {
  margin-top: var(--mantine-spacing-xs);
  color: var(--mantine-color-dimmed);
  font-size: rem(18px);
  line-height: 1.5;
  text-align: center;
  
  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(16px);
    margin-bottom: var(--mantine-spacing-xs);
    line-height: 1.4;
  }
}

.highlight {
  background: linear-gradient(90deg, var(--mantine-color-blue-5) 0%, var(--mantine-color-cyan-5) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
}

.features {
  width: 100%;
  padding: 0;
  margin-top: var(--mantine-spacing-lg);
  
  @media (max-width: var(--mantine-breakpoint-sm)) {
    margin-top: var(--mantine-spacing-md);
  }
}

.feature {
  position: relative;
  padding: var(--mantine-spacing-md);
  background: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-sm);
  margin-bottom: var(--mantine-spacing-md);
}

.featureIcon {
  background: linear-gradient(45deg, var(--mantine-color-blue-6) 0%, var(--mantine-color-cyan-6) 100%);
  margin-bottom: var(--mantine-spacing-sm);
}

.contactWrapper {
  margin-top: var(--mantine-spacing-lg);
  display: flex;
  justify-content: flex-start;
  gap: var(--mantine-spacing-md);
  max-width: rem(500px);
  margin-left: 0;
  margin-right: 0;
  text-align: left;

  @media (max-width: var(--mantine-breakpoint-sm)) {
    margin-top: var(--mantine-spacing-md);
    gap: var(--mantine-spacing-sm);
  }
}

.comingSoon {
  text-align: left;
  font-family: 'Greycliff CF', var(--mantine-font-family);
  font-size: rem(50px);
  line-height: 1.1;
  font-weight: 900;
  margin-bottom: var(--mantine-spacing-md);
  color: var(--mantine-color-black);

  @media (max-width: var(--mantine-breakpoint-sm)) {
    font-size: rem(38px);
    margin-bottom: var(--mantine-spacing-md);
    line-height: 1.15;
  }

  @media (max-width: var(--mantine-breakpoint-xs)) {
    font-size: rem(32px);
    margin-bottom: var(--mantine-spacing-sm);
  }
}

.tagline {
  font-size: clamp(14px, 2.5vw, 18px);
  margin-bottom: var(--mantine-spacing-sm);
  color: var(--mantine-color-black);
  font-weight: 500;
}

.logo {
  width: clamp(120px, 15vw, 180px);
  height: auto;
  margin: 0 auto var(--mantine-spacing-xl);
}

@media (max-width: var(--mantine-breakpoint-sm)) {
  .wrapper {
    padding-top: 3vh;
  }

  .content {
    padding: 0 var(--mantine-spacing-sm);
  }

  .inner {
    gap: var(--mantine-spacing-sm);
  }
}
