.wrapper {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  padding: var(--mantine-spacing-xl) 0;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

/* Common text handling */
.title, .description, .text {
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

/* Image handling */
.image {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Grid and flex containers */
.grid, .flex {
  width: 100%;
  max-width: 100vw;
  margin: 0;
}

/* Section title styles */
.sectionTitle {
  font-family: Greycliff CF, var(--mantine-font-family);
  font-size: rem(36px);
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: var(--mantine-spacing-xl);
  color: var(--mantine-color-black);
  word-break: break-word;
}

[data-mantine-color-scheme="dark"] .sectionTitle {
  color: var(--mantine-color-white);
}

@media (max-width: 48em) {
  .sectionTitle {
    font-size: rem(28px);
    padding: 0 var(--mantine-spacing-md);
  }
}

/* Mobile padding */
@media (max-width: 768px) {
  .wrapper {
    padding: var(--mantine-spacing-md) var(--mantine-spacing-xs);
  }
  
  .container {
    padding-left: var(--mantine-spacing-xs);
    padding-right: var(--mantine-spacing-xs);
  }
}

/* Tablet and up padding */
@media (min-width: 769px) {
  .wrapper {
    padding: var(--mantine-spacing-xl) 0;
  }
  
  .container {
    padding-left: var(--mantine-spacing-xl);
    padding-right: var(--mantine-spacing-xl);
  }
}
