.wrapper {
  padding: var(--mantine-spacing-md);
  padding-bottom: calc(var(--mantine-spacing-xl) * 3); /* Add extra padding at bottom */
  overflow-x: hidden;
}

.content {
  max-width: 100%;
  margin-right: var(--mantine-spacing-xl);
}

.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  color: var(--mantine-color-black);
  font-size: rem(36px);
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: var(--mantine-spacing-md);
  word-break: break-word;
  text-align: left;
}

.description {
  color: var(--mantine-color-dimmed);
  max-width: rem(500px);
  margin-bottom: var(--mantine-spacing-xl);
  word-break: break-word;
}

.form {
  background-color: var(--mantine-color-white);
  padding: var(--mantine-spacing-xl);
  border-radius: var(--mantine-radius-md);
  box-shadow: var(--mantine-shadow-lg);
  width: 100%;
  max-width: 100%;
}

.social {
  color: var(--mantine-color-black);
  display: flex;
  flex-wrap: wrap;
  gap: var(--mantine-spacing-md);
  margin-top: var(--mantine-spacing-xl);
  transition: color 200ms ease;
}

.social:hover {
  color: var(--mantine-primary-color-filled);
}

.socialIcon {
  width: rem(20px);
  height: rem(20px);
}

.input {
  background-color: var(--mantine-color-white);
  border-color: var(--mantine-color-gray-4);
  color: var(--mantine-color-black);
}

.input::placeholder {
  color: var(--mantine-color-gray-5);
}

.inputLabel {
  color: var(--mantine-color-black);
}

.control {
  background-color: var(--mantine-primary-color-filled);
  border: 0;
}

.control:hover {
  background-color: var(--mantine-primary-color-filled-hover);
}

.itemTitle {
  color: var(--mantine-color-black);
}

.itemDescription {
  color: var(--mantine-color-dimmed);
}

.itemIcon {
  color: var(--mantine-color-white);
  background-color: var(--mantine-primary-color-filled);
}

@media (max-width: 768px) {
  .wrapper {
    padding: var(--mantine-spacing-sm);
  }

  .content {
    margin-right: 0;
    margin-bottom: var(--mantine-spacing-md);
    text-align: left;
  }

  .form {
    padding: var(--mantine-spacing-md);
  }

  .social {
    justify-content: center;
  }

  .title {
    font-size: rem(28px);
  }
}
