.header {
  height: 60px;
  padding: 0;
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid var(--mantine-color-gray-3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;

  @media (max-width: 48em) {
    display: block;
  }
}

.logo {
  text-decoration: none;
  color: inherit;
}

.menuButton {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: var(--mantine-radius-sm);
  transition: background-color 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mantine-color-text);

  &:hover {
    background-color: var(--mantine-color-gray-0);
  }

  @media (hover: hover) {
    &:hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  }

  &:active {
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }

  svg {
    transition: transform 200ms ease;
  }
}

[data-mantine-color-scheme="dark"] .menuButton:hover {
  background-color: var(--mantine-color-dark-6);
}
