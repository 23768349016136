.footer {
  position: fixed;
  z-index: 100;
  border-top: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-5));
  margin-top: 0;
  background: var(--mantine-color-body);
  bottom: 0;
  width: 100%;
  background: var(--mantine-color-body);
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--mantine-spacing-xs);
  padding-bottom: var(--mantine-spacing-xs);
}

.social {
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.copyright {
  @media (max-width: 768px) {
    margin: 0;
  }
}
