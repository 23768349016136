.header {
  margin-bottom: var(--mantine-spacing-md);
  padding-bottom: var(--mantine-spacing-md);
  border-bottom: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
}

.title {
  font-family: Greycliff CF, var(--mantine-font-family);
  margin-bottom: 0;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: var(--mantine-font-size-sm);
  color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-1));
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-sm);
  border-radius: var(--mantine-radius-sm);
  font-weight: 500;
  width: 100%;

  @media (hover: hover) {
    &:hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
      color: light-dark(var(--mantine-color-black), var(--mantine-color-white));

      & .icon {
        color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
      }
    }
  }

  &:active {
    background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  }
}

.closeButton {
  transition: background-color 150ms ease;
  
  @media (hover: hover) {
    &:hover {
      background-color: light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-6));
    }
  }

  &:active {
    background-color: light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-5));
  }
}

[data-mantine-color-scheme="dark"] .link:hover {
  background-color: var(--mantine-color-dark-6);
}
