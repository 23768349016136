.header {
  height: 60px;
  padding: 0;
  background-color: var(--mantine-color-body);
  border-bottom: 1px solid var(--mantine-color-gray-3);
  margin-bottom: var(--mantine-spacing-md);

  @media (max-width: 48em) {
    display: var(--header-mobile-display, none);
  }
}

.inner {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: var(--mantine-font-size-lg);
  font-weight: 700;
  text-decoration: none;
  color: var(--mantine-color-black);
  transition: transform 200ms ease;
}

.logo:hover {
  transform: scale(1.05);
}

.logoText {
  font-family: Greycliff CF, var(--mantine-font-family);
  text-transform: uppercase;
  background: linear-gradient(45deg, var(--mantine-color-blue-filled), var(--mantine-color-cyan-filled));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.links {
  padding-top: var(--mantine-spacing-lg);
  padding-bottom: var(--mantine-spacing-lg);
}

.link {
  display: block;
  line-height: 1;
  padding: 8px 12px;
  border-radius: var(--mantine-radius-sm);
  text-decoration: none;
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);
  font-weight: 500;

  &:hover {
    background-color: var(--mantine-color-gray-0);
  }
}

[data-mantine-color-scheme="dark"] .link:hover {
  background-color: var(--mantine-color-dark-6);
}

.link[data-active] {
  background-color: var(--mantine-color-blue-light);
  color: var(--mantine-color-blue-filled);
  font-weight: 600;
}
